import {
  ChevronLeftIcon,
  ChevronRightIcon,
  XMarkIcon,
} from '@heroicons/react/20/solid';
import {
  DocumentArrowDownIcon,
  EllipsisVerticalIcon,
  PlusCircleIcon,
} from '@heroicons/react/24/outline';
import {
  BreadcrumbItem,
  Breadcrumbs,
  Button,
  Card,
  CardBody,
  Chip,
  Dropdown,
  DropdownItem,
  DropdownMenu,
  DropdownTrigger,
  Input,
  Pagination,
  Skeleton,
  Table,
  TableBody,
  TableCell,
  TableColumn,
  TableHeader,
  TableRow,
} from '@nextui-org/react';
import {AxiosError} from 'axios';
import React, {useEffect, useMemo, useRef, useState} from 'react';
import {useNavigate} from 'react-router-dom';

import axiosInstance from '../axiosInstance';
import ImportExamplesFromJsonModal from '../components/ImportExamplesFromJsonModal';
import {useNotifications} from '../notifications';
import {Dataset} from '../types/Dataset';
import {DatasetRow} from '../types/DatasetRow';

const DatasetDetailsPage: React.FC = () => {
  const [dataset, setDataset] = useState<Dataset>({
    name: '',
    inputs: [] as string[],
  } as Dataset);
  const [currentInput, setCurrentInput] = useState('');
  const [rows, setRows] = useState<DatasetRow[]>([]);
  const [importedRows, setImportedRows] = useState<DatasetRow[]>([]);
  const [datasetId, setDatasetId] = useState<null | string>(null);
  const [pagination, setPagination] = useState({
    page: 1,
    limit: 10,
    count: 0,
  });
  const navigate = useNavigate();
  const fileInputRef = useRef<HTMLInputElement>(null);
  const [isImportModalOpen, setIsImportModalOpen] = useState(false);
  const [rowsLoading, setRowsLoading] = useState(false);

  const isDatasetSaved = useMemo(() => {
    return !!datasetId && datasetId !== 'new';
  }, [datasetId]);

  const {showSuccess, showWarning, showError} = useNotifications();

  useEffect(() => {
    const datasetId = window.location.pathname.split('/').pop();

    if (!datasetId || datasetId === 'new') {
      return;
    }

    setDatasetId(datasetId);

    const fetchDataset = async () => {
      try {
        const response = await axiosInstance.get(`/datasets/${datasetId}`);
        setDataset(response.data);
      } catch (error) {
        if (error instanceof AxiosError && error.response?.status === 401) {
          return;
        }
        showError('Error fetching dataset');
        console.error('Error fetching dataset:', error);
      }
    };

    void fetchDataset();
  }, []);

  const fetchRows = async () => {
    try {
      const response = await axiosInstance.get(`/datasets/${datasetId}/rows`, {
        params: {
          page: pagination.page,
          limit: pagination.limit,
        },
      });
      const {data, meta} = response.data;

      setRows(data);

      setPagination(prevState => ({
        ...prevState,
        count: meta.count,
      }));

      setRowsLoading(false);
    } catch (error) {
      if (error instanceof AxiosError && error.response?.status === 401) {
        return;
      }
      console.error('Error fetching rows:', error);
      showError('Error fetching rows');
      setRowsLoading(false);
    }
  };

  useEffect(() => {
    if (!datasetId || datasetId === 'new') {
      return;
    }

    void fetchRows();
  }, [datasetId, pagination.page, pagination.limit]);

  const saveDataset = async () => {
    if (!datasetId || datasetId === 'new') {
      try {
        const response = await axiosInstance.post('/datasets', {
          ...dataset,
        });

        const datasetId = response.data[0].id;

        setDatasetId(datasetId);
        showSuccess('Dataset saved');

        navigate(`/datasets/${datasetId}`);
      } catch (error) {
        console.error('Error saving dataset:', error);
        showError('Error saving dataset');
      }
    } else {
      try {
        await axiosInstance.patch(`/datasets/${datasetId}`, {
          ...dataset,
        });

        showSuccess('Dataset updated');
      } catch (error) {
        showError('Error updating dataset');
        console.error('Error saving dataset:', error);
      }
    }
  };

  const handleDeleteDataset = async () => {
    if (!datasetId || datasetId === 'new') {
      showWarning('Cannot delete unsaved dataset');
      return;
    }

    try {
      await axiosInstance.delete(`/datasets/${datasetId}`);
      showSuccess('Dataset deleted');
      navigate('/datasets');
    } catch (error) {
      showError('Error deleting dataset');
      console.error('Error deleting dataset:', error);
    }
  };

  const handleNewRow = () => {
    navigate(`/datasets/${datasetId}/rows/new`);
  };

  const handleImportJson = async (e: React.ChangeEvent<HTMLInputElement>) => {
    const file = e.target.files![0];

    if (!file) {
      return;
    }

    const onLoad = (event: ProgressEvent<FileReader>) => {
      const jsonContent = event.target!.result as string;

      try {
        const content = JSON.parse(jsonContent);

        if (content.examples && Array.isArray(content.examples)) {
          setImportedRows(
            content.examples.map(
              (example: {input: Record<string, string>; target: string}) => {
                return {
                  input: example.input,
                  expected_output: example.target,
                };
              },
            ),
          );
        }

        setIsImportModalOpen(true);
      } catch (e) {
        showError('Error importing JSON');
        console.error('Error importing JSON:', e);
      }
    };

    const reader = new FileReader();
    reader.onload = onLoad;
    reader.readAsText(file);
  };

  const saveRows = async (rows: DatasetRow[]) => {
    try {
      await axiosInstance.post(`/datasets/${datasetId}/rows/bulk`, {
        rows: rows.map(row => ({
          inputs: row.input,
          expectedOutput: row.expected_output,
        })),
      });
      showSuccess('Rows saved');
    } catch (e) {
      showError('Error saving rows');
      console.error('Error saving rows:', e);
    }

    await fetchRows();
  };

  const exportJson = async () => {
    if (!datasetId || datasetId === 'new') {
      showWarning('Cannot export unsaved dataset');
      return;
    }

    try {
      const allRowsResponse = await axiosInstance.get(
        `/datasets/${datasetId}/rows`,
        {
          params: {
            page: 1,
            limit: pagination.count,
          },
        },
      );

      const allRows = allRowsResponse.data.data;

      if (!allRows || !Array.isArray(allRows)) {
        showError('No data to export');
        return;
      }

      const exportObject = {
        examples: allRows.map((row: DatasetRow) => ({
          input: row.input,
          target: row.expected_output,
        })),
      };

      const json = JSON.stringify(exportObject, null, 2);

      const blob = new Blob([json], {type: 'application/json'});

      const url = URL.createObjectURL(blob);

      const a = document.createElement('a');
      a.href = url;
      a.download = `${dataset.name}.json`;
      a.click();
      URL.revokeObjectURL(url);
    } catch (e) {
      showError('Error exporting JSON');
      console.error('Error exporting JSON:', e);
    }
  };

  return (
    <div className="relative min-h-screen bg-white">
      <div className="flex w-full flex-col items-start justify-start p-6">
        <div>
          <Skeleton isLoaded={!!dataset}>
            <Breadcrumbs>
              <BreadcrumbItem
                href="/datasets"
                className="font-chivo text-sm text-[#111827]"
              >
                Datasets
              </BreadcrumbItem>
              <BreadcrumbItem className="font-chivo text-sm text-[#111827]">
                {dataset?.name || 'New Dataset'}
              </BreadcrumbItem>
            </Breadcrumbs>
          </Skeleton>
        </div>
        <div className="mt-3 flex w-full flex-row items-center justify-between">
          <Skeleton isLoaded={!!dataset}>
            <h1 className="font-chivo text-2xl font-normal text-[#111827]">
              {dataset?.name || 'New Dataset'}
            </h1>
          </Skeleton>
          <div className="flex flex-row flex-nowrap items-center justify-between">
            <Button
              radius="none"
              className="font-chivo border-2 border-[#D1D5DB] bg-white text-sm font-medium"
              onClick={saveDataset}
            >
              Save
            </Button>
            <Dropdown radius="none">
              <DropdownTrigger>
                <Button
                  radius="none"
                  className="ml-3 border-2 border-[#D1D5DB] bg-white"
                  isIconOnly
                >
                  <EllipsisVerticalIcon className="h-4 w-4" />
                </Button>
              </DropdownTrigger>
              <DropdownMenu>
                <DropdownItem
                  key={'export'}
                  className="font-chivo text-right text-sm font-medium text-[#374151]"
                  onClick={exportJson}
                >
                  Export JSON
                </DropdownItem>
                <DropdownItem
                  key={'delete'}
                  onClick={handleDeleteDataset}
                  className="font-chivo text-right text-sm font-medium text-[#F31260]"
                >
                  Delete Dataset
                </DropdownItem>
              </DropdownMenu>
            </Dropdown>
          </div>
        </div>
        <div className="mt-3 w-full">
          <Input
            labelPlacement="outside"
            className="w-2/3"
            radius="none"
            name="name"
            placeholder={'Enter dataset name...'}
            classNames={{
              label: 'font-chivo text-sm',
              input: 'bg-white',
              inputWrapper:
                'bg-white border-1 border-[#E9E8E9] font-roboto text-[#374151]',
            }}
            label="Name"
            value={dataset?.name}
            onValueChange={(value: string) => {
              setDataset(prevDataset => ({
                ...prevDataset,
                name: value,
              }));
            }}
          />
        </div>
        <div className="mt-5 w-full">
          <Input
            labelPlacement="outside"
            className="w-2/3"
            radius="none"
            name="inputs"
            readOnly={rows.length > 0}
            classNames={{
              label: 'font-chivo text-sm',
              input: 'bg-white',
              inputWrapper:
                'bg-white border-1 border-[#E9E8E9] font-chivo text-[#374151]',
            }}
            placeholder={rows.length > 0 ? '' : 'Enter input...'}
            label="Inputs"
            value={currentInput}
            onChange={e => setCurrentInput(e.target.value)}
            onKeyDown={e => {
              if (e.key === 'Enter' || e.key === ',') {
                e.preventDefault();
                const trimmedValue = currentInput.trim();

                if (trimmedValue && !dataset.inputs.includes(trimmedValue)) {
                  setDataset(prevDataset => ({
                    ...prevDataset,
                    inputs: [...prevDataset.inputs, trimmedValue],
                  }));
                }

                setCurrentInput('');
              }
            }}
            startContent={
              dataset.inputs.length > 0 ? (
                <div className="mr-1.5 flex flex-row flex-nowrap gap-2">
                  {dataset?.inputs.map((input, index) => {
                    return (
                      <Chip
                        variant="flat"
                        className="font-chivo bg-[#485ED5] text-xs text-white"
                        key={index}
                        classNames={{
                          base: 'rounded-[3px]',
                        }}
                        radius="none"
                        onClose={
                          rows.length === 0
                            ? () => {
                                setDataset(prevDataset => ({
                                  ...prevDataset,
                                  inputs: prevDataset.inputs.filter(
                                    (prevInput: string) => prevInput !== input,
                                  ),
                                }));
                              }
                            : undefined
                        }
                        endContent={
                          rows.length === 0 ? (
                            <XMarkIcon className="h-4 w-4" />
                          ) : (
                            ''
                          )
                        }
                      >
                        {input}
                      </Chip>
                    );
                  })}
                </div>
              ) : (
                ''
              )
            }
          />
        </div>
        <div className="mt-5 flex w-full flex-col">
          <div className="flex flex-row flex-nowrap items-center justify-between">
            <h2 className="font-roboto text-medium font-medium text-[#111827]">
              Rows
            </h2>
            <div className="flex flex-row flex-nowrap items-center justify-between gap-2">
              <input
                style={{display: 'none'}}
                ref={fileInputRef}
                title="Upload JSON"
                type="file"
                accept=".json"
                onClick={e => {
                  const target = e.target as HTMLInputElement;
                  target.value = '';
                }}
                onChange={handleImportJson}
              />
              <Button
                disabled={!isDatasetSaved}
                radius="none"
                className={`font-roboto px-2 text-sm font-medium ${isDatasetSaved ? 'border-1 border-[#D1D5DB] bg-[#F9FAFB] text-[#6B7280]' : 'border-none bg-gray-50 text-gray-500 ring-0 hover:border-none'}`}
                startContent={<DocumentArrowDownIcon className="h-5 w-5" />}
                onClick={() => {
                  if (fileInputRef.current) {
                    (fileInputRef.current as HTMLInputElement).click();
                  }
                }}
              >
                Import JSON
              </Button>
              <Button
                disabled={!isDatasetSaved}
                radius="none"
                className={`font-roboto px-2 text-sm font-medium ${isDatasetSaved ? 'border-1 border-[#D1D5DB] bg-[#F9FAFB] text-[#6B7280]' : 'border-none bg-gray-50 text-gray-500 ring-0 hover:border-none'}`}
                startContent={<PlusCircleIcon className="h-5 w-5" />}
                onClick={handleNewRow}
              >
                New Row
              </Button>
            </div>
          </div>
          {rows.length > 0 ? (
            <Card
              className="mt-3 w-full border-1 border-[#E9E8E9]"
              radius="none"
              shadow="none"
              style={{
                boxShadow: '0px 0px 4px 0px rgba(0, 0, 0, 0.05)',
              }}
            >
              <CardBody className="p-0 text-center text-[#6B7280]">
                <Table
                  className="m-0 w-full border-none p-0"
                  radius="none"
                  shadow="none"
                >
                  <TableHeader
                    columns={[
                      {key: 'id', label: 'id'},
                      {key: 'inputs', label: 'input'},
                      {key: 'expected_output', label: 'expected output'},
                      {key: 'last_modified', label: 'last modified'},
                    ]}
                    style={{
                      boxShadow: '0px 0px 4px 0px rgba(0, 0, 0, 0.05)',
                      border: '1px solid rgba(233, 232, 233, 1)',
                    }}
                  >
                    {column => {
                      return (
                        <TableColumn
                          key={column.key}
                          className="font-chivo text-xs font-normal uppercase text-[#6B7280]"
                        >
                          {column.label}
                        </TableColumn>
                      );
                    }}
                  </TableHeader>
                  <TableBody emptyContent={'No data'} items={rows}>
                    {(row: DatasetRow) => (
                      <TableRow key={row.id}>
                        {columnKey => {
                          switch (columnKey) {
                            case 'id':
                              return (
                                <TableCell className="w-1/4">
                                  <a
                                    href={`/datasets/${datasetId}/rows/${row.id}`}
                                    className="font-chivo text-xs font-semibold text-[#3C82F6]"
                                  >
                                    {row.id}
                                  </a>
                                </TableCell>
                              );
                            case 'inputs':
                              return (
                                <TableCell>
                                  <Input
                                    isReadOnly={true}
                                    value={JSON.stringify(row.input)}
                                    radius="sm"
                                    classNames={{
                                      input:
                                        'bg-white text-xs text-[#6B7280] dark:text-[#6B7280] group-data-[has-value=true]:text-[#6B7280] p-1',
                                      inputWrapper:
                                        'bg-white border-1 border-[#AEAEAE] font-chivo text-[#6B7280] p-1',
                                    }}
                                  />
                                </TableCell>
                              );
                            case 'expected_output':
                              return (
                                <TableCell>
                                  <Input
                                    isReadOnly={true}
                                    value={row.expected_output}
                                    radius="sm"
                                    classNames={{
                                      input:
                                        'bg-white text-xs text-[#6B7280] dark:text-[#6B7280] group-data-[has-value=true]:text-[#6B7280] p-1',
                                      inputWrapper:
                                        'bg-white border-1 border-[#AEAEAE] font-chivo text-[#6B7280] p-1',
                                    }}
                                  />
                                </TableCell>
                              );
                            case 'last_modified':
                              return (
                                <TableCell>
                                  {new Date(
                                    row.updated_at || row.created_at,
                                  ).toLocaleDateString()}
                                </TableCell>
                              );
                            default:
                              return <TableCell>Unknown parameter</TableCell>;
                          }
                        }}
                      </TableRow>
                    )}
                  </TableBody>
                </Table>
              </CardBody>
            </Card>
          ) : (
            <Card
              className="mt-3 w-full border-1 border-[#E9E8E9] py-12"
              radius="none"
              shadow="none"
              style={{
                boxShadow: '0px 0px 4px 0px rgba(0, 0, 0, 0.05)',
              }}
            >
              <CardBody>
                <span className="font-chivo text-center text-sm font-normal text-[#6B7280]">
                  {!isDatasetSaved && 'Save the dataset to start adding rows.'}
                  {isDatasetSaved && (
                    <>
                      <a
                        href="#"
                        className="text-[#485ED5]"
                        onClick={() => {
                          if (fileInputRef.current) {
                            (fileInputRef.current as HTMLInputElement).click();
                          }
                        }}
                      >
                        Import from a JSON file
                      </a>{' '}
                      or{' '}
                      <a
                        href="#"
                        className="text-[#485ED5]"
                        onClick={handleNewRow}
                      >
                        add a Row
                      </a>
                    </>
                  )}
                </span>
              </CardBody>
            </Card>
          )}
        </div>
        <div className="my-6 flex w-full flex-row items-center justify-center">
          <Button
            radius="full"
            isIconOnly
            className="mr-4 bg-[#485ED5] p-0 text-white disabled:bg-[#D9D9D9] disabled:text-[#6B7280]"
            disabled={pagination.page === 1 || rowsLoading}
            onClick={() => {
              setRowsLoading(true);
              setPagination(prevState => ({
                ...prevState,
                page: Math.max(1, pagination.page - 1),
              }));
            }}
          >
            <ChevronLeftIcon className="h-5 w-5" />
          </Button>
          <Pagination
            total={Math.ceil(pagination.count / pagination.limit)}
            page={pagination.page}
            isDisabled={rowsLoading}
            onChange={(page: number) => {
              setRowsLoading(true);
              setPagination(prevState => ({
                ...prevState,
                page,
              }));
            }}
            disableAnimation={true}
            classNames={{
              base: 'p-0 rounded-full bg-[#D9D9D9]',
              wrapper: 'rounded-full bg-[#D9D9D9]',
              cursor: 'bg-[#485ED5]',
              item: 'h-10 w-10 rounded-md bg-[#D9D9D9] font-roboto font-medium text-[#6B7280] text-md shadow-none',
            }}
          />
          <Button
            radius="full"
            isIconOnly
            className="ml-4 bg-[#485ED5] p-0 text-white disabled:bg-[#D9D9D9] disabled:text-[#6B7280]"
            disabled={
              pagination.page ===
                Math.ceil(pagination.count / pagination.limit) || rowsLoading
            }
            onClick={() => {
              setRowsLoading(true);
              setPagination(prevState => ({
                ...prevState,
                page: Math.min(
                  Math.ceil(pagination.count / pagination.limit),
                  pagination.page + 1,
                ),
              }));
            }}
          >
            <ChevronRightIcon className="h-5 w-5" />
          </Button>
        </div>
      </div>
      <ImportExamplesFromJsonModal
        isOpen={isImportModalOpen}
        onClose={() => setIsImportModalOpen(false)}
        onImport={saveRows}
        importedRows={importedRows}
        dataset={dataset}
      />
    </div>
  );
};

export default DatasetDetailsPage;
