import {Session} from '@supabase/supabase-js';
import {SuperblocksEmbed} from '@superblocksteam/embed-react';
import {SuperblocksApp} from '@superblocksteam/embed-react/dist/types';
import React, {FC, useEffect, useRef, useState} from 'react';
import {useNavigate} from 'react-router-dom';

import {getUserTeam} from '../PromptServerClient';
import axiosInstance from '../axiosInstance';
import {useNotifications} from '../notifications';

export type AgenticAutomationPageProps = {
  session: Session | null;
};

export const AgenticAutomationPage: FC<AgenticAutomationPageProps> = ({
  session,
}) => {
  const [agenticToken, setAgenticToken] = useState<string | null>(null);
  const appRef = useRef<SuperblocksApp>(null);
  const [superblocksIntegrationEnabled, setSuperblocksIntegrationEnabled] =
    useState(false);
  const [checked, setChecked] = useState(false);

  const navigate = useNavigate();
  const notify = useNotifications();

  useEffect(() => {
    const fetchUserTeam = async () => {
      const userTeam = await getUserTeam();

      if (session && userTeam) {
        const userInTeam = userTeam.find(
          (member: {email: string}) => member.email === session.user.email,
        );
        setChecked(true);
        setSuperblocksIntegrationEnabled(userInTeam.superblocksIntegration);
      }
    };

    if (session) {
      void fetchUserTeam();
    }
  }, [session]);

  useEffect(() => {
    if (checked && !superblocksIntegrationEnabled) {
      navigate('/404');
    }
  }, [superblocksIntegrationEnabled, checked]);

  useEffect(() => {
    const fetchAgenticToken = async () => {
      const response = await axiosInstance.get('/teams/agentic/token');
      setAgenticToken(response.data.access_token);
    };

    void fetchAgenticToken();
  }, []);

  const handleAgenticEvent = (
    event: string,
    payload: Record<string, unknown>,
  ) => {
    switch (event) {
      case 'ApproveUser':
        notify.showSuccess(
          `${payload.userName} has been approved in embedded app`,
        );
        break;
      default:
        notify.showWarning(`Unhandled event: ${event}`);
    }
  };

  return (
    <>
      {!agenticToken && <div>Loading...</div>}
      {agenticToken && (
        <SuperblocksEmbed
          src="https://app.superblocks.com/embed/applications/f532956c-3982-4e9c-a58b-3189a54c3caf"
          properties={{hostUserEmail: 'test@test.test'}}
          onEvent={handleAgenticEvent}
          ref={appRef}
          token={agenticToken}
        />
      )}
    </>
  );
};
