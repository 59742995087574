import {ChevronLeftIcon, ChevronRightIcon} from '@heroicons/react/20/solid';
import {
  Button,
  Card,
  CardBody,
  getKeyValue,
  Pagination,
  Table,
  TableBody,
  TableCell,
  TableColumn,
  TableHeader,
  TableRow,
} from '@nextui-org/react';
import {AxiosError} from 'axios';
import React, {useEffect, useMemo, useState} from 'react';
import {useNavigate} from 'react-router-dom';

import axiosInstance from '../axiosInstance';
import {useNotifications} from '../notifications';
import {Dataset} from '../types/Dataset';

const columns = [
  {
    key: 'name',
    label: 'Name',
  },
  {
    key: 'size',
    label: 'Size',
  },
  {
    key: 'createdOn',
    label: 'Created On',
  },
  {
    key: 'lastModified',
    label: 'Last Modified',
  },
];

const DatasetsListPage: React.FC = () => {
  const [datasets, setDatasets] = useState<Dataset[]>([]);
  const [pagination, setPagination] = useState({
    page: 1,
    limit: 10,
    count: 0,
  });
  const navigate = useNavigate();
  const [datasetsLoading, setDatasetsLoading] = useState(false);

  const {showError} = useNotifications();

  const datasetsDisplayData = useMemo(() => {
    if (!datasets || datasets.length === 0) {
      return [];
    }

    return datasets.map(dataset => ({
      id: dataset.id,
      name: dataset.name,
      size: `${dataset.dataset_rows![0].count || 0} rows, ${dataset.inputs.length} columns`,
      createdOn: new Date(dataset.created_at).toLocaleDateString(),
      lastModified:
        (dataset.updated_at &&
          new Date(dataset.updated_at).toLocaleDateString()) ||
        new Date(dataset.created_at).toLocaleDateString(),
    }));
  }, [datasets]);

  useEffect(() => {
    const fetchDatasets = async () => {
      try {
        const response = await axiosInstance.get('/datasets', {
          params: {
            page: pagination.page,
            limit: pagination.limit,
          },
        });
        const {data, meta} = await response.data;
        setDatasets(data);
        setPagination(prevState => {
          return {
            ...prevState,
            count: meta.count,
          };
        });

        setDatasetsLoading(false);
      } catch (error) {
        if (error instanceof AxiosError && error.response?.status === 401) {
          return;
        }
        showError('Error fetching datasets');
        console.error('Error fetching datasets:', error);
        setDatasetsLoading(false);
      }
    };

    void fetchDatasets();
  }, [pagination.page, pagination.limit]);

  const handleNewDataset = () => {
    navigate('/datasets/new');
  };

  return (
    <div className="relative min-h-screen bg-white">
      <div className="flex flex-col items-start justify-start p-6">
        <div className="flex w-full flex-row flex-nowrap justify-between">
          <h1 className="font-chivo text-2xl font-normal text-[#111827]">
            Datasets
          </h1>
          <Button
            radius="none"
            className="bg-[#485ED5]"
            onClick={handleNewDataset}
          >
            <span className="text-sm font-normal text-white">New Dataset</span>
          </Button>
        </div>
        <Card
          className="mt-6 w-full"
          radius="none"
          shadow="none"
          style={{
            boxShadow: '0px 0px 4px 0px rgba(0, 0, 0, 0.05)',
            border: '1px solid rgba(233, 232, 233, 1)',
          }}
        >
          <CardBody className="px-0 py-1">
            <Table className="w-full border-none" radius="none" shadow="none">
              <TableHeader
                columns={columns}
                style={{
                  boxShadow: '0px 0px 4px 0px rgba(0, 0, 0, 0.05)',
                  border: '1px solid rgba(233, 232, 233, 1)',
                }}
              >
                {column => {
                  return (
                    <TableColumn
                      key={column.key}
                      className="font-chivo text-xs font-normal uppercase text-[#6B7280]"
                    >
                      {column.label}
                    </TableColumn>
                  );
                }}
              </TableHeader>
              <TableBody emptyContent={'No data'} items={datasetsDisplayData}>
                {dataset => (
                  <TableRow key={dataset.id}>
                    {columnKey => {
                      switch (columnKey) {
                        case 'name':
                          return (
                            <TableCell>
                              <a
                                href={`/datasets/${dataset.id}`}
                                className="font-chivo text-sm font-semibold text-[#3C82F6]"
                              >
                                {dataset.name}
                              </a>
                            </TableCell>
                          );
                        case 'size':
                          return (
                            <TableCell>
                              <span className="font-chivo text-sm font-normal text-[#6B7280]">
                                {dataset.size}
                              </span>
                            </TableCell>
                          );
                        case 'createdOn':
                          return (
                            <TableCell>
                              <span className="font-chivo text-sm font-normal text-[#6B7280]">
                                {dataset.createdOn}
                              </span>
                            </TableCell>
                          );
                        case 'lastModified':
                          return (
                            <TableCell>
                              <span className="font-chivo text-sm font-normal text-[#6B7280]">
                                {dataset.lastModified}
                              </span>
                            </TableCell>
                          );
                        default:
                          return (
                            <TableCell>
                              <span className="font-chivo text-sm font-normal text-[#6B7280]">
                                {getKeyValue(dataset, columnKey)}
                              </span>
                            </TableCell>
                          );
                      }
                    }}
                  </TableRow>
                )}
              </TableBody>
            </Table>
          </CardBody>
        </Card>
      </div>
      <div className="my-6 flex w-full flex-row items-center justify-center">
        <Button
          radius="full"
          isIconOnly
          className="mr-4 bg-[#485ED5] p-0 text-white disabled:bg-[#D9D9D9] disabled:text-[#6B7280]"
          disabled={pagination.page === 1 || datasetsLoading}
          onClick={() => {
            setDatasetsLoading(true);
            setPagination(prevState => ({
              ...prevState,
              page: Math.max(1, pagination.page - 1),
            }));
          }}
        >
          <ChevronLeftIcon className="h-5 w-5" />
        </Button>
        <Pagination
          total={Math.ceil(pagination.count / pagination.limit)}
          page={pagination.page}
          isDisabled={datasetsLoading}
          onChange={(page: number) => {
            setDatasetsLoading(true);
            setPagination(prevState => ({
              ...prevState,
              page,
            }));
          }}
          disableAnimation={true}
          classNames={{
            base: 'p-0 rounded-full bg-[#D9D9D9]',
            wrapper: 'rounded-full bg-[#D9D9D9]',
            cursor: 'bg-[#485ED5]',
            item: 'h-10 w-10 rounded-md bg-[#D9D9D9] font-roboto font-medium text-[#6B7280] text-md shadow-none',
          }}
        />
        <Button
          radius="full"
          isIconOnly
          className="ml-4 bg-[#485ED5] p-0 text-white disabled:bg-[#D9D9D9] disabled:text-[#6B7280]"
          disabled={
            pagination.page ===
              Math.ceil(pagination.count / pagination.limit) || datasetsLoading
          }
          onClick={() => {
            setDatasetsLoading(true);
            setPagination(prevState => ({
              ...prevState,
              page: Math.min(
                Math.ceil(pagination.count / pagination.limit),
                pagination.page + 1,
              ),
            }));
          }}
        >
          <ChevronRightIcon className="h-5 w-5" />
        </Button>
      </div>
    </div>
  );
};

export default DatasetsListPage;
