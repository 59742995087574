import {Button, Card, CardBody, CardHeader, Input} from '@nextui-org/react';
import React, {useEffect, useState} from 'react';
import {useNavigate} from 'react-router-dom';

import axiosInstance from '../axiosInstance';
import {useNotifications} from '../notifications';
import useStore, {StorageKeys} from '../store';

export default function NewPassword({
  clearAppSession,
}: {
  clearAppSession: () => void;
}) {
  const [password, setPassword] = useState('');
  const [confirmPassword, setConfirmPassword] = useState('');
  const [passwordCriteria, setPasswordCriteria] = useState({
    length: false,
    uppercase: false,
    lowercase: false,
    number: false,
    specialCharacter: false,
    passwordMatch: false,
  });
  const [passwordStrength, setPasswordStrength] = useState(0);

  const [accessToken, setAccessToken] = useState('');
  const [type, setType] = useState('');
  const [refreshToken, setRefreshToken] = useState('');

  const notify = useNotifications();
  const navigate = useNavigate();

  const passwordCriteriaDescriptions = {
    length: 'At least 8 characters',
    uppercase: 'At least one uppercase letter',
    lowercase: 'At least one lowercase letter',
    number: 'At least one number',
    specialCharacter: 'At least one special character',
    passwordMatch: 'Passwords match',
  };

  useEffect(() => {
    const hash = window.location.hash;

    const params = new URLSearchParams(hash.slice(1));
    const accessTokenString = params.get('access_token');
    const typeString = params.get('type');
    const refreshTokenString = params.get('refresh_token');

    setAccessToken(accessToken || accessTokenString || '');
    setType(type || typeString || '');
    setRefreshToken(refreshToken || refreshTokenString || '');
  });

  useEffect(() => {
    const checkPasswordStrength = () => {
      let strength = 0;
      const criteria = {
        length: password.length >= 8,
        uppercase: /[A-Z]/.test(password),
        lowercase: /[a-z]/.test(password),
        number: /[0-9]/.test(password),
        specialCharacter: /[^A-Za-z0-9]/.test(password),
        passwordMatch: password.length > 0 && password === confirmPassword,
      };

      for (const key in criteria) {
        if (criteria[key as keyof typeof criteria]) {
          strength += 1;
        }
      }

      setPasswordCriteria(criteria);
      setPasswordStrength(strength);
    };

    checkPasswordStrength();
  }, [password, confirmPassword]);

  const handleUpdatePassword = async () => {
    if (passwordStrength < 5) {
      notify.showError('Password does not meet criteria');
      return;
    }

    const updatePassword = async () => {
      try {
        const {status} = await axiosInstance.patch('/teams/user/password', {
          accessToken,
          password,
          refreshToken,
        });

        if (status === 200) {
          const handleLogout = () => {
            Object.values(StorageKeys).forEach(key =>
              localStorage.removeItem(key),
            );
            useStore.setState(useStore.getInitialState());
            clearAppSession();
          };

          notify.showSuccess('Password updated successfully');
          handleLogout();
          setTimeout(() => {
            navigate('/login');
          }, 2000);
        }
        notify.showSuccess('Password updated successfully');
      } catch (error) {
        console.error('Error updating password:', error);
        notify.showError('Failed to update password');
      }
    };

    void updatePassword();
  };

  return (
    <div className="flex min-h-screen items-center justify-center bg-gray-100">
      <Card className="w-full max-w-xl" radius="none">
        <CardHeader className="flex flex-col items-center pb-0 pt-6">
          <h1 className="font-chivo text-2xl font-light text-primary">
            Welcome to Narrow AI
          </h1>
          <p className="text-lg text-gray-600">
            Start saving up to 95% of your LLM costs today.
          </p>
        </CardHeader>
        <CardBody className="max-w-m py-4">
          <div className="mb-6 flex flex-col items-center justify-center bg-gray-50 p-4">
            {Object.keys(passwordCriteria).map((key, index) => (
              <div
                key={index}
                className={`flex w-full items-center justify-start p-2 text-xs ${
                  passwordCriteria[key as keyof typeof passwordCriteria]
                    ? 'text-green-500'
                    : 'text-red-500'
                }`}
              >
                <span className="mr-2">
                  {passwordCriteria[key as keyof typeof passwordCriteria]
                    ? '✅'
                    : '❌'}
                </span>
                {
                  passwordCriteriaDescriptions[
                    key as keyof typeof passwordCriteria
                  ]
                }
              </div>
            ))}
          </div>
          <Input
            type="password"
            value={password}
            onChange={e => setPassword(e.target.value)}
            className="mb-3"
            radius="none"
            placeholder="New Password"
          />
          <Input
            type="password"
            className="mb-6"
            value={confirmPassword}
            onChange={e => setConfirmPassword(e.target.value)}
            radius="none"
            placeholder="Confirm Password"
          />
          <Button radius="none" color="primary" onClick={handleUpdatePassword}>
            Update Password
          </Button>
        </CardBody>
      </Card>
    </div>
  );
}
