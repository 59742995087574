import './App.css';
import {Auth} from '@supabase/auth-ui-react';
import {ThemeSupa} from '@supabase/auth-ui-shared';
import {Session, createClient} from '@supabase/supabase-js';
import {SnackbarProvider} from 'notistack';
import React, {useState, useEffect, useCallback, useRef} from 'react';
import {
  BrowserRouter as Router,
  Routes,
  Route,
  useNavigate,
  useLocation,
} from 'react-router-dom';

import GettingStartedCard from './components/GettingStartedCard';
import Modal from './components/SettingsModal';
import Sidebar from './components/Sidebar';
import {AgenticAutomationPage} from './pages/AgenticAutomationPage';
import Dashboard from './pages/Dashboard';
import DatasetDetailsPage from './pages/DatasetDetailsPage';
import DatasetRowDetailsPage from './pages/DatasetRowDetailsPage';
import DatasetsListPage from './pages/DatasetsListPage';
import DocsPage from './pages/Docs';
import Login from './pages/Login';
import NewPassword from './pages/NewPasswordPage';
import NewPromptPage from './pages/NewPromptPage';
import NotFound from './pages/NotFound';
import PromptDetailPage from './pages/PromptDetailPage';
import PromptPage from './pages/PromptPage';
import PromptTrainingPage from './pages/PromptTraining';
import useStore, {StorageKeys} from './store';
import {ModelConfig} from './types/ModelConfig';
import {PromptLog} from './types/PromptLog';
import env from '../env.js';

const supabase = createClient(env.SUPABASE_URL, env.SUPABASE_CLIENT_KEY);

const initializeLocalStorage = () => {
  if (!localStorage.getItem(StorageKeys.GETTINGSTARTEDTASKS)) {
    const initialTasks = [
      {
        id: 1,
        text: 'Run your first optimization',
        completed: false,
        icon: 'ArrowPathIcon',
      },
      {
        id: 2,
        text: 'Import your own prompt',
        completed: false,
        icon: 'ArrowUpTrayIcon',
      },
      {
        id: 3,
        text: 'Collect prompt data',
        completed: false,
        icon: 'ChartBarIcon',
      },
    ];
    localStorage.setItem(
      StorageKeys.GETTINGSTARTEDTASKS,
      JSON.stringify(initialTasks),
    );
  }
};

const updateTask = (taskId: number, completed: boolean) => {
  const tasks = JSON.parse(
    localStorage.getItem(StorageKeys.GETTINGSTARTEDTASKS) || '[]',
  );
  const updatedTasks = tasks.map((task: any) =>
    task.id === taskId ? {...task, completed} : task,
  );
  localStorage.setItem(
    StorageKeys.GETTINGSTARTEDTASKS,
    JSON.stringify(updatedTasks),
  );
};

export default function App() {
  const [isLoading, setIsLoading] = useState(true);
  const [session, setSession] = useState<Session | null>(null);
  const [settingsModalOpen, setSettingsModalOpen] = useState(false);
  const [gettingStartedTasks, setGettingStartedTasks] = useState([]);

  const mainContentRef = useRef<HTMLDivElement>(null);

  const userNavigation = [
    {
      name: 'Settings',
      href: '#',
      onClick: () => {
        setSettingsModalOpen(true);
      },
    },
  ];

  const modelConfig = useStore<ModelConfig>(state => state.modelConfig);

  const registerSupabase = () => {
    supabase.auth.getSession().then(({data: {session}}) => {
      setSession(session);
      if (session) {
        useStore.getState().setSession(session);
      }
      setIsLoading(false);
    });

    const {
      data: {subscription},
    } = supabase.auth.onAuthStateChange((_event, session) => {
      setSession(session);
      if (session) {
        useStore.getState().setSession(session);
      }
    });

    return () => subscription.unsubscribe();
  };

  useEffect(() => {
    registerSupabase();
    initializeLocalStorage();
    const tasks = JSON.parse(
      localStorage.getItem(StorageKeys.GETTINGSTARTEDTASKS) || '[]',
    );
    setGettingStartedTasks(tasks);
  }, []);

  const CheckSession = () => {
    const navigate = useNavigate();
    const location = useLocation();

    useEffect(() => {
      const handleSessionChange = () => {
        if (!session && location.pathname === '/new-password') {
          return;
        }

        if (!session && location.pathname !== '/login') {
          navigate('/login');
        } else if (session && location.pathname === '/login') {
          navigate('/');
        }
      };

      handleSessionChange();
    }, [session, navigate, location]);

    return null;
  };

  const scrollToBottom = () => {
    if (mainContentRef.current) {
      setTimeout(() => {
        mainContentRef.current.lastElementChild?.scrollIntoView({
          behavior: 'smooth',
          block: 'end',
        });
      }, 300);
    }
  };

  return (
    <>
      {session && (
        <Modal open={settingsModalOpen} setOpen={setSettingsModalOpen} />
      )}
      <SnackbarProvider>
        <Router>
          <div className="flex h-screen bg-gray-100">
            {!isLoading && <CheckSession />}{' '}
            {(isLoading || session) &&
            window.location.pathname !== '/new-password' ? (
              <Sidebar
                session={session}
                userNavigation={userNavigation}
                isLoading={isLoading}
                GettingStartedCard={
                  <GettingStartedCard tasks={gettingStartedTasks} />
                }
              />
            ) : null}
            <main className="flex-1 overflow-y-auto" ref={mainContentRef}>
              <Routes>
                <Route
                  path="/login"
                  element={
                    <Login
                      auth={
                        <Auth
                          supabaseClient={supabase}
                          appearance={{theme: ThemeSupa}}
                          providers={['google']}
                        />
                      }
                    />
                  }
                />
                <Route
                  path="/new-password"
                  element={
                    <NewPassword clearAppSession={() => setSession(null)} />
                  }
                />
                <Route path="/" element={<Dashboard />} />
                <Route
                  path="/prompts/create"
                  element={
                    <NewPromptPage
                      onSuccess={() => {
                        updateTask(2, true);
                        setGettingStartedTasks(
                          JSON.parse(
                            localStorage.getItem(
                              StorageKeys.GETTINGSTARTEDTASKS,
                            ) || '[]',
                          ),
                        );
                      }}
                    />
                  }
                />
                <Route path="/docs" element={<DocsPage />} />
                <Route path="/prompts" element={<Dashboard />} />
                <Route path="/datasets" element={<DatasetsListPage />} />
                <Route
                  path="/datasets/:datasetId"
                  element={<DatasetDetailsPage />}
                />
                <Route path="/datasets/new" element={<DatasetDetailsPage />} />
                <Route
                  path="/datasets/:datasetId/rows/:rowId"
                  element={<DatasetRowDetailsPage />}
                />
                <Route
                  path="/datasets/:datasetId/rows/new"
                  element={<DatasetRowDetailsPage />}
                />
                <Route
                  path="/training/prompts"
                  element={
                    <PromptTrainingPage
                      modelConfig={modelConfig}
                      session={session!}
                      scrollToBottom={scrollToBottom}
                      onSuccess={() => {
                        updateTask(1, true);
                        setGettingStartedTasks(
                          JSON.parse(
                            localStorage.getItem(
                              StorageKeys.GETTINGSTARTEDTASKS,
                            ) || '[]',
                          ),
                        );
                      }}
                    />
                  }
                />
                <Route
                  path="/prompts/:promptId"
                  element={
                    <PromptPage
                      onLogsFound={useCallback(
                        (logs: PromptLog[]) => {
                          if (logs.length > 0) {
                            updateTask(3, true);
                            setGettingStartedTasks(
                              JSON.parse(
                                localStorage.getItem(
                                  StorageKeys.GETTINGSTARTEDTASKS,
                                ) || '[]',
                              ),
                            );
                          }
                        },
                        [updateTask, setGettingStartedTasks],
                      )}
                    />
                  }
                />
                <Route
                  path="/prompts/:promptId/logs/:logId"
                  element={<PromptDetailPage />}
                />
                <Route path="*" element={<NotFound />} />
                <Route
                  path="/agentic"
                  element={<AgenticAutomationPage session={session} />}
                />
              </Routes>
            </main>
          </div>
        </Router>
      </SnackbarProvider>
    </>
  );
}
