export enum ErrorType {
  UNAUTHORIZED = 'unauthorized',
}

export class CustomError extends Error {
  constructor(
    readonly type: ErrorType,
    message: string,
  ) {
    super(message);
  }
}
